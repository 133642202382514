// AdviceForm.js
import React, { useState } from "react";
import { getRecommendation } from "../utils/recommendationAlgo";
import ChatbotWindow from "./ChatbotWindow";

function AdviceForm(props) {
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [shoulderWidth, setShoulderWidth] = useState("");
  const [bust, setBust] = useState("");
  const [waist, setWaist] = useState("");
  const [hip, setHip] = useState("");
  const [advice, setAdvice] = useState("");
  const [showForm, setShowForm] = useState(true);

  const { selectedDress } = props; // 接收选定的服装对象

  const isChildrenClothing = selectedDress.tag.includes("k");
  const isWomenClothing = selectedDress.tag.includes("w");

  const [showChatbot, setShowChatbot] = useState(false);

  const handleChatbotClick = () => {
    setShowChatbot(true);
  };

  const serializeUserInput = () => {
    const inputs = {
      age: { value: age, unit: "years" },
      height: { value: height, unit: "cm" },
      weight: { value: weight, unit: "kg" },
      shoulderWidth: { value: shoulderWidth, unit: "cm" },
      bust: { value: bust, unit: "cm" },
      waist: { value: waist, unit: "cm" },
      hip: { value: hip, unit: "cm" }
    };
    return Object.entries(inputs)
      .filter(([_, { value }]) => value !== "")
      .map(([key, { value, unit }]) => `${key}: ${value}${unit}`)
      .join(", ");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // 根据提交的信息生成建议
    const formData = {
      age,
      height,
      weight,
      shoulderWidth,
      bust,
      waist,
      hip,
    };

    const { recommendation } = getRecommendation(selectedDress, formData);
    setAdvice(recommendation);
    setShowForm(false);
  };
  const handleModify = () => {
    setShowForm(true);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "2rem",
          borderRadius: "8px",
          width: "400px",
          maxWidth: "90%",
          maxHeight: "96%", // 添加最大高度限制
          overflowY: "auto", // 添加垂直滚动条
          position: "relative",
        }}
      >
        <button
          onClick={props.onClose}
          style={{
            position: "absolute",
            top: "0.1rem",
            right: "0.1rem",
            background: "none",
            border: "none",
            fontSize: "3rem",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        <h2
          style={{
            marginBottom: "1.5rem",
            textAlign: "center",
            color: "#333",
            fontSize: "1.2rem",
          }}
        >
          {selectedDress.name}
        </h2>
        {showForm ? (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "#f8f8f8",
              padding: "1.5rem",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            {isChildrenClothing && (
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="age"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Age:
                </label>
                <input
                  type="number"
                  id="age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  required
                  style={{
                    width: "96%",
                    padding: "0.5rem",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              </div>
            )}
            <div style={{ marginBottom: "1rem" }}>
              <label
                htmlFor="height"
                style={{ display: "block", marginBottom: "0.5rem" }}
              >
                Height (cm):
              </label>
              <input
                type="number"
                id="height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                required
                style={{
                  width: "96%",
                  padding: "0.5rem",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label
                htmlFor="weight"
                style={{ display: "block", marginBottom: "0.5rem" }}
              >
                Weight (kg):
              </label>
              <input
                type="number"
                id="weight"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                required
                style={{
                  width: "96%",
                  padding: "0.5rem",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </div>
            {isWomenClothing && (
              <div>
                <div style={{ marginBottom: "1rem" }}>
                  <label
                    htmlFor="shoulderWidth"
                    style={{ display: "block", marginBottom: "0.5rem" }}
                  >
                    Shoulder Width (cm):
                  </label>
                  <input
                    type="number"
                    id="shoulderWidth"
                    value={shoulderWidth}
                    onChange={(e) => setShoulderWidth(e.target.value)}
                    style={{
                      width: "96%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                </div>

                <div style={{ marginBottom: "1rem" }}>
                  <label
                    htmlFor="bust"
                    style={{ display: "block", marginBottom: "0.5rem" }}
                  >
                    Bust (cm):
                  </label>
                  <input
                    type="number"
                    id="bust"
                    value={bust}
                    onChange={(e) => setBust(e.target.value)}
                    style={{
                      width: "96%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                </div>

                <div style={{ marginBottom: "1rem" }}>
                  <label
                    htmlFor="waist"
                    style={{ display: "block", marginBottom: "0.5rem" }}
                  >
                    Waist (cm):
                  </label>
                  <input
                    type="number"
                    id="waist"
                    value={waist}
                    onChange={(e) => setWaist(e.target.value)}
                    style={{
                      width: "96%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <label
                    htmlFor="hip"
                    style={{ display: "block", marginBottom: "0.5rem" }}
                  >
                    Hip (cm):
                  </label>
                  <input
                    type="number"
                    id="hip"
                    value={hip}
                    onChange={(e) => setHip(e.target.value)}
                    style={{
                      width: "96%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                </div>
              </div>
            )}
            <button
              type="submit"
              style={{
                width: "100%",
                padding: "0.75rem",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              GET ADVICE
            </button>
          </form>
        ) : (
          <div
            style={{
              backgroundColor: "#f8f8f8",
              padding: "1rem",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h3
              style={{
                marginBottom: "1rem",
                color: "#333",
                fontSize: "1.25rem",
              }}
            >
              Our Advice:
            </h3>
            <p
              style={{
                whiteSpace: "pre-wrap",
                color: "#555",
                lineHeight: "1.6",
              }}
            >
              {advice}
            </p>
            <p style={{ marginTop: "1rem" }}>
              Have questions? Try asking our AI Advisor!
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <button
                onClick={handleModify}
                style={{
                  padding: "0.5rem 1rem",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "0.9rem",
                  transition: "background-color 0.3s",
                  fontWeight: "bold",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#0056b3";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#007bff";
                }}
              >
                Modify Input
              </button>
              <button
                onClick={handleChatbotClick}
                style={{
                  padding: "0.5rem 1rem",
                  backgroundColor: "#28a745",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "0.9rem",
                  transition: "background-color 0.3s",
                  fontWeight: "bold",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#218838";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#28a745";
                }}
              >
                Chat with AI Advisor
              </button>
            </div>
          </div>
        )}
        {showChatbot && (
          <div style={{ marginTop: "1rem" }}>
            <ChatbotWindow
              selectedDress={selectedDress}
              userInput={serializeUserInput()}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AdviceForm;
